const ThemeStyles = {
  colors: {
    text: "#32094F",
    white: "#fff",
    black: "#000",
    primary: "#7F26C4",
    purple: "#8020C4",
  },
  fonts: {
    body: `'Poppins', sans-serif`,
    heading: "expressway, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: "140%",
    heading: "120%",
  },
  fontSizes: [
    "0.75rem", //12px
    "0.8125rem", //13px
    "0.875rem", //14px
    "1rem", //16px
    "1.0625rem", //17px
    "1.125rem", //18px
    "1.25rem", //20px
    "1.3125rem", //21px
    "1.4375rem", //23px
    "1.5rem", //24px
    "1.75rem", //28px
    "1.875rem", //30px
    "2rem", //32px
    "2.375rem", //38px
    "2.5625rem", //41px
    "3rem", //48px
    "3.125rem", //50px
    "3.375rem", //54px
    "4rem", //64px
    "4.1875rem", //67px
    "4.5rem", //72px
  ],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    container: 1200,
  },
  breakpoints: ["768px", "1024px", "1200px", "1450px"],
  styles: {
    root: {
      color: "text",
      fontFamily: "body",
      fontSize: "100%",
      fontWeight: "body",
      lineHeight: "body",
      margin: 0,
    },
    h1: {
      color: "inherit",
      fontFamily: "heading",
      fontSize: [17, 19],
      fontStyle: "italic",
      fontWeight: "bold",
      lineHeight: "heading",
      textTransform: "uppercase",
    },
    h2: {
      color: "inherit",
      fontFamily: "heading",
      fontSize: [14, 16],
      fontStyle: "italic",
      fontWeight: "bold",
      lineHeight: "heading",
      textTransform: "uppercase",
    },
    h3: {
      color: "inherit",
      fontSize: [11, 13],
      fontStyle: "italic",
      fontWeight: "bold",
      lineHeight: "heading",
      textTransform: "uppercase",
    },
    h4: {
      color: "inherit",
      fontFamily: "heading",
      fontSize: [8, 10],
      lineHeight: "heading",
      textTransform: "uppercase",
    },
    h5: {
      color: "inherit",
      fontSize: [4, 7],
      fontStyle: "italic",
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h6: {
      color: "inherit",
      fontSize: 0,
    },
    p: {
      color: "inherit",
      fontFamily: "body",
      fontSize: [1, 3],
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    srOnly: {
      height: 1,
      left: -10000,
      overflow: "hidden",
      position: "absolute",
      top: "auto",
      width: 1,
    },
  },
  buttons: {
    primary: {
      backgroundColor: "#7F26C4",
      borderRadius: "70px",
      color: "white",
      cursor: "pointer",
      fontFamily: "heading",
      fontSize: 3,
      lineHeight: "heading",
      px: 5,
      py: 3,
      textAlign: "center",
      textTransform: "uppercase",
    },
  },
  forms: {
    label: {},
    input: {
      backgroundColor: "white",
      borderColor: "purple",
      borderRadius: "40px",
      borderStyle: "solid",
      borderWidth: "1px",
      color: "text",
      fontFamily: "heading",
      fontSize: [2, 3],
      fontWeight: "body",
      px: 4,
      py: 3,
      transition: "box-shadow 0.1s",
      "::placeholder": {
        textTransform: "uppercase",
        color: "text",
      },
      ":focus": {
        outline: "none",
        boxShadow: "0.5rem 0.5rem 0.5rem 0rem rgba(0,0,0,0.4)",
      },
    },
    textarea: {},
    radio: {},
    checkbox: {},
    legend: {},
  },
  code: {
    fontFamily: "monospace",
    fontSize: "inherit",
  },
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
  },
  th: {
    textAlign: "left",
    borderBottomStyle: "solid",
  },
  td: {
    textAlign: "left",
    borderBottomStyle: "solid",
  },
  img: {
    maxWidth: "100%",
  },
  layout: {
    container: {
      maxWidth: 1200,
      px: 3,
    },
  },
};

export default ThemeStyles;
